@import "./colors.css";

/* general */
.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.edit_header_icon_close {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}

.edit_header_icon_add {
    cursor: pointer;
    margin-right: 15px;
    vertical-align: sub;
    font-size: 1.5rem;
    color: var(--primary--color);
}

.error_div {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

